import green from '@material-ui/core/colors/green';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React, { ReactNode, useState } from 'react';
import { connect } from 'react-redux';

import Logo from '../../components/Logo/Logo';
import Navigation from '../../components/Navigation/Navigation';
import NavigationItems from '../../components/Navigation/NavigationItems/NavigationItems';
import { IAppState } from '../../interfaces/state';
import { IUser } from '../../interfaces/domain';

const classes = require("./Layout.module.scss");

const theme = createTheme({
  palette: {
    primary: { main: green[600] },
  }
});

interface IStateProps {
  loading: boolean;
  children: ReactNode;
  user: IUser | null;
}

interface IProps extends IStateProps {}

const Layout: React.FC<IProps> = ({ loading, children, user }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const drawerClosedHandler = () => {
    setShowDrawer(false);
  };

  const drawerToggleHandler = () => {
    setShowDrawer(!showDrawer);
  };

  let content = children;

  if (loading) {
    content = <LinearProgress color="secondary" />;
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Drawer open={showDrawer} onClose={drawerClosedHandler}>
        <div
          tabIndex={0}
          role="button"
          onClick={drawerClosedHandler}
          onKeyDown={drawerClosedHandler}
        >
          <div className={classes.LogoContainer}>
            <Logo height={50} />
          </div>
          <Divider />
          <NavigationItems user={user} />
        </div>
      </Drawer>
      <Navigation
        onToggleDrawer={drawerToggleHandler}
        isAuthenticated={user !== null}
        loading={loading}
      />
      <MuiThemeProvider theme={theme}>
        <main className={classes.Content}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={8}>
              {content}
            </Grid>
          </Grid>
        </main>
      </MuiThemeProvider>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    loading: state.auth.loading,
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(Layout);
