import { Button, CircularProgress, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axios from '../../axios';
import PendingFiles from '../../components/PendingFiles/PendingFiles';
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog';
import Input, { EInputType } from '../../components/UI/Input/Input';
import { UNION_TARGET_GROUPS, unionEducationContentOptions, unionTargetGroupOptions } from '../../data/union';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import { IUnion } from '../../interfaces/domain/IUnion';
import { IAppState } from '../../interfaces/state';
import {
  defaultInputChangedHandler,
  getFormData,
  IControls,
  IInputConfigOptional,
  validateInput,
} from '../../shared/utility';
import * as actions from '../../store/actions';

const classes = require("./UnionEducation.module.scss");

export enum EFields {
  union = "union",
  unionOther = "unionOther",
  name = "name",
  educationDate = "educationDate",
  targetGroups = "targetGroups",
  targetGroupAthleteInfo = "targetGroupAthleteInfo",
  targetGroupOther = "targetGroupOther",
  participantsAmount = "participantsAmount",
  instructor = "instructor",
  instructorEmail = "instructorEmail",
  educationContents = "educationContents",
  educationContentsOther = "educationContentsOther",
  educationContentDescription = "educationContentDescription",
  comments = "comments",
  attachments = "attachments",
}

const initControls: IControls = {
  controls: {
    [EFields.union]: {
      type: EInputType.select,
      value: "",
      label: "Urheilujärjestö",
      options: [],
      validation: {
        required: true,
        or: ["unionOther"],
      },
      valid: false,
    },
    [EFields.unionOther]: {
      type: EInputType.text,
      value: "",
      label: "Muu urheilujärjestö",
      validation: {
        required: true,
        or: ["union"],
      },
      valid: false,
    },
    [EFields.name]: {
      type: EInputType.text,
      value: "",
      label: "Koulutuksen nimi",
      validation: {
        required: true,
      },
      valid: false,
    },
    [EFields.educationDate]: {
      type: EInputType.date,
      value: null,
      label: "Päivämäärä",
      validation: {
        required: true,
      },
      valid: false,
    },
    [EFields.targetGroups]: {
      type: EInputType.select,
      value: [],
      label: "Kohderyhmät",
      options: unionTargetGroupOptions,
      multiple: true,
      validation: {
        required: true,
        or: ["targetGroupOther"],
      },
      valid: false,
    },
    [EFields.targetGroupAthleteInfo]: {
      type: EInputType.text,
      value: "",
      label: "Urheilijat kohderyhmän tarkennus",
      validation: {
        required: false,
      },
      valid: true,
    },
    [EFields.targetGroupOther]: {
      type: EInputType.text,
      value: "",
      label: "Muu kohderyhmä",
      validation: {
        required: true,
        or: ["targetGroups"],
      },
      valid: false,
    },
    [EFields.participantsAmount]: {
      type: EInputType.number,
      value: "",
      label: "Osallistujamäärä",
      validation: {
        required: true,
      },
      valid: false,
    },
    [EFields.instructor]: {
      type: EInputType.text,
      value: "",
      label: "Kouluttaja",
      validation: {
        required: true,
      },
      valid: false,
    },
    [EFields.instructorEmail]: {
      type: EInputType.email,
      value: "",
      label: "Sähköposti",
      validation: {
        required: true,
        email: true,
      },
      valid: false,
    },
    [EFields.educationContents]: {
      type: EInputType.select,
      value: [],
      label: "Koulutuksen sisältö",
      options: unionEducationContentOptions,
      multiple: true,
      validation: {
        required: true,
        or: ["educationContentsOther"],
      },
      valid: false,
    },
    [EFields.educationContentsOther]: {
      type: EInputType.text,
      value: "",
      label: "Muu koulutuksen sisältö",
      validation: {
        required: true,
        or: ["educationContents"],
      },
      valid: false,
    },
    [EFields.educationContentDescription]: {
      type: EInputType.text,
      value: "",
      label: "Kuvaus koulutuksen sisällöstä",
      multiline: true,
      validation: {
        required: false,
      },
      valid: true,
    },
    [EFields.comments]: {
      type: EInputType.text,
      value: "",
      label:
        "Muita kommentteja koulutuksesta (esim. osallistujapalaute) tai toiveita SUEKille",
      multiline: true,
      validation: {
        required: false,
      },
      valid: true,
    },
    [EFields.attachments]: {
      type: EInputType.dropzone,
      value: "",
      label: "Liitteet",
      validation: {
        required: false,
      },
      valid: true,
    },
  },
  isValid: false,
};

interface IProps {}

const UnionEducation: React.FC<IProps> = () => {
  const [controls, setControls] = useState(initControls);
  const [isOpen, setOpen] = useState(false);
  const [pendingFiles, setPendingFiles] = useState<File[]>([]);

  const dispatch = useDispatch();
  const { unions, unionsLoading, user, unionEducation, loading } = useSelector(
    (state: IAppState) => ({
      loading: state.union.loading,
      unionEducation: state.union.unionEducation,
      unions: state.union.unions,
      unionsLoading: state.union.unionsLoading,
      user: state.auth.user,
    })
  );

  useEffect(() => {
    dispatch(actions.getUnions());
  }, [dispatch]);

  useEffect(() => {
    if (user !== null) {
      setControls((prevControls) => {
        let newControls = validateInput(
          prevControls.controls,
          EFields.instructor,
          user.name,
          false
        );
        if (user.email) {
          newControls = validateInput(
            newControls.controls,
            EFields.instructorEmail,
            user.email,
            false
          );
        }
        return newControls;
      });
    }
  }, [user]);


  const populateUnionOptions = (unions: IUnion[] | null) => {
    if (unions !== null) {
      setControls((prevControls) => {
        return {
          ...prevControls,
          controls: {
            ...prevControls.controls,
            [EFields.union]: {
              ...prevControls.controls[EFields.union],
              options: unions.map((union) => ({
                text: union.name,
                value: union.id,
              })),
            },
          },
        };
      });
    }
  }

  useEffect(() => {
    populateUnionOptions(unions);
  }, [unions]);

  useEffect(() => {
    if (unionEducation && unionEducation.id) {
      setOpen(true);
    }
  }, [unionEducation]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const controlsData = getFormData(controls.controls);

    const formData = new FormData();
    if(pendingFiles && pendingFiles.length > 0) {
      pendingFiles.forEach((file, index) => {
        formData.append(`file_${index + 1}`, file);
      });
      formData.append("fileAmount", pendingFiles.length.toString());
    }
    formData.append("data", JSON.stringify(controlsData));

    dispatch(actions.saveUnionEducation(formData));
  };

  const onResetForm = () => {
    setControls(initControls);
    setPendingFiles([]);
    populateUnionOptions(unions);
  };

  const onCloseDialog = () => {
    setOpen(false);
    onResetForm();
  };

  const createInput = (inputName: EFields, config?: IInputConfigOptional) => {
    return (
      <Input
        formControl={{
          id: inputName,
          config: {
            ...controls.controls[inputName],
            ...config,
          },
        }}
        changed={(value, controlName: string) =>
          defaultInputChangedHandler(value, controlName, setControls)
        }
      />
    );
  };

  const dropHandler = (files: File[]) => {
    setPendingFiles((prevFiles) => [...prevFiles, ...files]);
  };


  const removePendingFileHandler = (index: number) => {
    setPendingFiles(prevFiles => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    })
  }

  const hasAthleteSelected = (controls.controls[EFields.targetGroups]
    .value as string[]).includes(UNION_TARGET_GROUPS.ATHLETES);

  return (
    <React.Fragment>
      <CustomDialog
        title="Kiitos"
        content="Olemme vastaanottaneet koulutuksen tiedot, kiitos."
        buttonText="Sulje"
        onOk={onCloseDialog}
        open={isOpen}
      />
      <Paper elevation={4} className={classes.Paper}>
        <Typography variant="h5" gutterBottom>
          Lajiliittojen järjestämät koulutukset
        </Typography>
        <Typography variant="subtitle1">
          Tällä lomakkeella voit ilmoittaa lajiliittosi toteuttamat antidoping-
          ja kilpailumanipulaatiokoulutukset. Huomioithan, että SUEKilta
          tilattuja koulutuksia ei raportoida tämän lomakkeen kautta.
        </Typography>
        {unionsLoading || loading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={onSubmit}>
            {createInput(EFields.union)}
            {createInput(EFields.unionOther)}
            {createInput(EFields.name)}
            {createInput(EFields.educationDate)}
            {createInput(EFields.targetGroups)}
            {hasAthleteSelected && createInput(EFields.targetGroupAthleteInfo)}
            {createInput(EFields.targetGroupOther)}
            {createInput(EFields.participantsAmount)}
            {createInput(EFields.instructor)}
            {createInput(EFields.instructorEmail)}
            {createInput(EFields.educationContents)}
            {createInput(EFields.educationContentsOther)}
            {createInput(EFields.educationContentDescription)}
            {createInput(EFields.comments)}

            <p>Lataa liitteeksi käyttämäsi koulutusmateriaali ja osallistujalista</p>
            {createInput(EFields.attachments, { onDropHandler: dropHandler })}
            <PendingFiles 
              pendingFiles={pendingFiles}
              onRemove={removePendingFileHandler}
            />
            

            <Button
              color="primary"
              className={classes.Button}
              type="submit"
              disabled={!controls.isValid}
            >
              Lähetä
            </Button>

            <Button className={classes.Button} onClick={onResetForm}>
              Tyhjennä
            </Button>
          </form>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default withErrorHandler<IProps>(UnionEducation, axios);
