import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { ReactNode } from 'react';

interface IProps {
  title: string;
  content: ReactNode;
  open: boolean;
  buttonText: string;
  onOk: () => void;
  onCancel?: () => void;
}

const CustomDialog: React.FC<IProps> = ({
  title,
  content,
  open,
  buttonText,
  onOk,
  onCancel
}) => (
  <Dialog
    onClose={(_, reason) => {
      /*
      if (reason === "backdropClick") {
        
      }
      */
    }}
    disableEscapeKeyDown
    maxWidth="xs"
    aria-labelledby="custom-dialog"
    open={open}
  >
    <DialogTitle id="custom-dialog-title">{title}</DialogTitle>
    <DialogContent>{content}</DialogContent>
    <DialogActions>
      {onCancel && <Button onClick={onCancel}>Peruuta</Button>}
      <Button onClick={onOk} color="primary">
        {buttonText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default CustomDialog;
